<template>
  <div id="cgu_hotline">
    <MentionsLegalesHotlineDe v-if="country === 'de'" />
    <MentionsLegalesHotlineFr v-else />
  </div>
</template>

<script>
import MentionsLegalesHotlineFr from "@/components/legal/hotline/MentionsLegalesHotlineFr.vue";
import MentionsLegalesHotlineDe from "@/components/legal/hotline/MentionsLegalesHotlineDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de Hotline dans la bonne langue.
 */
export default {
  name: "MentionsLegalesHotline",
  components: {
    MentionsLegalesHotlineFr,
    MentionsLegalesHotlineDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
